.bt-confirm-container{
    position: fixed;
    inset: 0;
    background: rgba(19, 19, 19, 0.3);
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bt-confirm{
    width: 90%;
    max-width: 500px;
    margin:40px;
    min-height: 300px;
    z-index: 1000;
    border-radius: 10px;
    padding: 15px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background:rgb(255, 255, 255);
    box-shadow: 0 0 1em 0.2em rgba(0, 0, 0, 0.1);
    animation-name: example; 
    animation-duration: .5s;
}

.bt-buttons-container{
    display: flex;
    gap: 1em;
    margin-top: 1em;
    align-items: center;
    justify-content: space-around;
}

.bt-buttons-container>button{
    padding: 0.8em 1.5em;
    border-radius: 0.2em;
    border: none;
    outline: none;
    cursor: pointer;
    background: white;
    color: black;
}

.bt-buttons-container>button.accept{
    background: green;
    color: white;
}

.bt-buttons-container>button.cancel{
    background: rgb(230, 230, 230);
    color: black;
}


@keyframes example {
    from {top: 10%;}
    to {top: 13%;}
  }