.myProfileSection{
    padding: 2em 1em;

}

.myProfileSection>div {
    
}


.myProfileOrdersSection,
.myProfileInfo{
    padding: 1em;
    /* box-shadow: 0 0 0.9em 0.01em rgba(0, 0, 0, 0.1); */
    background: white;
    height: fit-content;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
    box-shadow: 0 0 2em 0.01em rgba(0, 0, 0, 0.1);
}

.myProfileInfo>h2,
.myProfileOrdersSection>h2{
    font-weight: 400;
    text-align: left;
    width: 100%;
    font-size: 1em;
    color: gray;
}

.myProfileInfoImg{
    border: 1px solid var(--header-dark);
    width: 10em;
    height: 10em;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.myProfileInfoTxt{
    width: 100%;
    gap: 0.5em;
    text-align: center;
}

.myProfileInfoTxt>h3{
    font-size: 1.5em;
    font-weight: 450;
}

.myProfileInfoTxt>p{
    font-size: 0.9em;
    font-weight: 450;
}

.myProfileInfoTxtAddress{
    text-align: left;
}

.myProfileInfoTxtAddress h3{
    font-weight: 500;
    font-size: 1em;

}

.myProfileInfoTxtAddress p{
    font-size: 0.9em;
    color: grey;
}

/* ==============================Order Window css======================================== */

.myProfileOrdersSection{
    /* flex-wrap: wrap; */
    height: fit-content;

}

.myprofileOrderDiv{
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.orderCardSection{
    
    padding: 0.5em;
    background: white;
    cursor: pointer;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}



.orderCardImg{
    width: 100%;
    padding-top: 142%;
    position: relative;
}

.orderCardImgAbs{
    position: absolute;
    inset: 0;
    overflow: hidden;
    z-index: 1;
}

.orderCardImgAbs>img{
    width: 100%;
    height: 100%;
    color: rgba(190,190,190, 1);
    background: rgba(245,245,245, 1);
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.orderCardTxt{
    text-align: left;
    padding: 0.8em;
}

.orderCardTxt>h2{
    /* font-size: 1.2em; */
    font-weight: 450;
    color: grey;
}

.orderCardPriceAbs,
.orderCardStatusAbs{
    position: absolute;
    top: 2em;
    left: 0;
    padding: 0.5em 1em;
    z-index: 99;
    background: rgba(255, 255, 255, 0.9);
}

.orderCardStatusAbs{
    background: rgba(255, 255, 255, 0.9);
    top: 6em;
}


@media only screen and (min-width: 10px){
    .myProfileSection>div {
        width: 100%;
    }

    .myProfileSection>div>div {
        gap: 1em;
        flex-direction: column-reverse;
    }

    .myProfileOrdersSection,
    .myProfileInfo{
        width: 100%;
    }

    .myProfileOrdersSection>div{
        flex-wrap: wrap;
    }

    .orderCardTxt>h2{
        font-size: 0.8em;
    }

    .orderCardTxt>p{
        font-size: 0.9em;
        color: grey;
    }

    .myprofileOrderDiv{
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 700px){
    .myProfileOrdersSection>div{
        flex-wrap: nowrap;
    }

    .orderCardTxt>h2{
        font-size: 0.9em;
    }

    .orderCardTxt>p{
        font-size: 0.8em;
    }

    .myprofileOrderDiv{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 900px){
        
    .myProfileSection>div>div {
        gap: 1em;
        flex-direction: row;
    }

    .myProfileOrdersSection{
        width: 70%;

    }
    .myProfileInfo{
        width: 30%;
    }
}


@media only screen and (min-width: 1200px){
    .myProfileSection>div {
        width: 100%;
    }
    .myProfileSection>div>div {
        gap: 2em;
    }
}