.form-mini-wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.5em;
    padding: 1.5em;
    background: white;
    border-radius: 0.5em;
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.05);
}

.form-mini-wrapper-body{
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.form-mini-wrapper-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-mini-wrapper-back-div>h2{
    font-size: 1.15em;
    color: rgb(53, 53, 53);
}

.form-mini-btn{
    padding: 1em  2em;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.submit{
    background: rgb(0, 95, 0);
    color: white;
}

.form-mini-wrapper-footer{
    display: flex;
    justify-content: center;
    padding-top: 1em;
}




.form-mini-group-heading{
    color: #808088;
    font-size: 1em;
    margin: 1.5em 0;
}
.form-mini-group-section{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2em;
}

.form-mini-group-section>div{
    flex-grow: 1;
}

@media only screen and (min-width: 768px){
    .form-mini-section{
        width: 48%;
    }
}

@media only screen and (min-width: 1200px){
    .form-mini-section{
        width: 31%;
    }
}