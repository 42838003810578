.panelNavbarSection{
    padding: 0.85em 2em;
    background: var(--sec-color);
    align-items: center;
    position: fixed;
    width: 96%;
    z-index: 99;
}

.panelNavbarContent{
    justify-content: flex-end;
    align-items: center;
}

.panelNavbarSection ul{
    align-items: center;
}

.panelNavbarSection a{
    font-size: 1.5em;
}

.panelNavbarProfile{
    width: 2.5em;
    height: 2.5em;
    border-radius: 1em;
    -webkit-border-radius: 1em;
    -moz-border-radius: 1em;
    -ms-border-radius: 1em;
    -o-border-radius: 1em;
}

.panelNavbarLogoDiv{
    display: flex ;
    align-items: center;
}