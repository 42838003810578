.login-layout-out{
    display: flex;
    /* background:#f2f2f2; */
    background: url('../../Assets/images/hero-slider.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.login-layout-overlay{
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0,0.5);
    z-index: -9;
}

.login-layout-right{
    z-index: 9;
}

.login-layout-left,
.login-layout-right{
    width: '50%';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.login-layout-left{
    /* border-right: 1px solid lightblue; */
}