@import url('https://fonts.googleapis.com/css2?family=Dosis&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap');

:root{

  --border: rgba(0,0,0,0.3);
  --shadow: 0 0 1em 0.4em rgba(0,0,0,0.2);

  --pri-color: rgb(145, 189, 238);
  --pri-color-2: rgb(254, 234, 234);
  --sec-color: rgb(255, 255, 255);


  --header-dark: rgb(28, 26, 26);
  --hover-light: ;
  --dark-color: rgb(17, 28, 67);

  --light-color: rgba(17, 28, 67, 0.05);

  --border: rgba(0, 0, 0, 0.2);

  --btn-outline: rgb(87, 68, 118);

}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* font-family: 'Dosis', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Poppins', sans-serif;
  color: var(--dark-color);
}

hr{
  width: 100%;
  color: var(--dark-color);
  opacity: 0.1;
}


ul{
  list-style: none;
}

a{
  color: #323232;
  text-decoration: none;
}

.flex{ 
  display: flex;
}

.flex-centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.gap-half{
  gap: 0.5em;
}
.gap-1{
  gap: 1em;
}
.gap-2{
  gap: 2em;
}
.gap-3{
  gap: 3em;
}
.gap-4{
  gap: 4em;
}
.gap-5{
  gap: 5em;
}

.w-100{
  width: 100%;
}

.w-80{
  width: 80%;
}

.w-50{
  width: 50%;
}



@media only screen and (min-width: 10px){

}

@media only screen and (min-width: 768px){

}

@media only screen and (min-width: 1200px){
  
}

