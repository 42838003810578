.banner-detail-section{
    padding: 1em;
    background: white;
    box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.05);
    border-radius: 0.5em;
    display: flex;

}

.banner-image-section{
    width: 100%;
    position: relative;
    padding-top: 66.66%;
    overflow: hidden;
}

.banner-image-abs-div{
    position: absolute;
    inset: 0;
}

.banner-image-abs-div>img{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}

.banner-text-section{
    width: 40%;
    padding: 0 2em ;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.banner-text-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.banner-details-card{
    display: flex;
    justify-content: space-between;
}

.banner-details-card>b{
    font-size: 0.8em;
    color: rgb(148, 148, 148);
}

.banner-details-card>p{
    font-size: 0.85em;
}