.panelProductDetailSection{
    padding: 2em;
    overflow-y: hidden;
}

.panelProductDiv{
    /* width: 80%; */
    padding: 2em 2em;
    width: 100%;
    background: white;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.1);
}

.panelProductDetailImgContainer{
    /* width: 65%; */
}

.panelProductDetailImgContainer>div{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.panelProductDetailImgDiv{
    position: relative;
    background: rgb(241, 241, 241);
}

.panelProductDetailImgAbsDiv{
    position: absolute;
    gap: 2.5em;
    inset: 0;
}

.panelProductDetailImgAbsDiv img{
    max-width: 100%;
    max-height: 100%;
    grid-area: img;
}




/* ===============================productDetailsCardSection========================================= */

.productDetailsCardSection{

}

.productDetailsCardSection hr{
    opacity: 0.1;
    border: none;
    border-bottom: 1px solid grey;
}

.productDetailsCardSection h3,
.productDetailsCardSection p{
    font-size: 0.8em;
    font-weight: 450;
    color: grey;
    padding: 1em 0;

}

.productTextSection,
.panelProductDetailImgContainer{
    
}

.productTextSection h2{
    font-size: 1em;
    font-weight: 500;
    padding-bottom:0.5em;
}


@media only screen and (min-width: 100px){
    .panelProductDetail>div{
        width: 100%;
    }
    .panelProductDetailDiv{
        width: 100%;
    }

    .panelProductDetailDiv{
        flex-direction: column;
    }

    .panelProductDetailImgContainer{
        width: 100%;
    }
    
    .productTextContentSection {
        width: 100%;
    }

    .panelProductDetailImgContainer>div{
        flex-direction: column;
    }

    .panelProductDetailImgDiv{
        padding-top: 130%;
    }

    .productTextContentSection>.buttonSection button{
        padding: 1em 1.5em;
        font-size: 0.9em;
    }

    .productTextContentSection>.buttonSection{
        width: 100%;
    }

    .panelProductDetailImgContainer>div{
        display: grid;
        grid-template-columns: 1fr;

    }
}

@media only screen and (min-width: 768px){
    
    .panelProductDetailDiv{
        /* width: 90%; */
    }

    .panelProductDetailImgContainer>div{
        flex-direction: row;
    }

    .panelProductDetailImgDiv{
        padding-top: 130%;
    }

    .productTextContentSection>.buttonSection button{
        padding: 0.5em 1.5em;
        font-size: 1em;
    }

    .productTextContentSection>.buttonSection{
        /* width: 60%; */
    }

    .panelProductDetail>div{
        /* width: 90%; */
    }

    .panelProductDetailImgContainer>div{
        display: grid;
        height: 100%;

    }
}

@media only screen and (min-width: 1200px){
    

    .panelProductDetail{
        padding: 2em 0;
    }

    .panelProductDetailDiv{
        flex-direction: row;
    }

    .panelProductDetailImgContainer{
        /* width: 50%; */
    }
    
    .productTextContentSection {
        /* width: 50%; */
    }
}


@media only screen and (min-width: 1500px){
    .panelProductDetailDiv{
        width: 80%;
    }

    .panelProductDetail{
        padding: 2em;
    }

    .panelProductDetail>div{
        width: 80%;
    }
}

