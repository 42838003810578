.checkboxSection{
    width: 100%;
    gap: 0.5em;
    flex-direction: row-reverse;
}

.checkbox{
    width: fit-content;
}

.checkbox:hover,
.checkbox:focus{
    border: 1px solid rgba(163, 163, 163,1);
}

.checkbox::placeholder{
    color: lightgray;
}

.checkboxLabel{
    font-size: 0.8em;
    text-align: left !important;
    align-items: center;
    font-weight: 400;
    /* margin-bottom: -0.5em; */
}

.inputWarning{
    font-size: 0.6em;
    color: rgb(163, 163, 163);
}

.checkbox-error{
    font-size: 0.6em;
    color: red;
    text-align: center;
}
