

.panelLayoutSlideMenu{
    /* width: 100%;
    max-width: 15em; */
    /* overflow: hidden; */
}

.panelLayoutContent{
    padding: 2em;
    background: rgb(240, 241, 247);
    min-height: 94vh;
    overflow-y: auto;
}