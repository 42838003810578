.list-wrapper{
    width: 100%;
    background-color: white;
    border-radius: 0.5em;
    box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.05);
    height: fit-content;
    padding: 15px;
}

.list-div{
    display: flex;
    flex-direction: column;
}

.list-filter-buttons{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin:10px auto
}
.list-filter-buttons .lfb-button{
    background-color: pink;
    padding: 5px 10px;
    background-color: #f1f1f1;
    border:1px solid grey;
    border-radius: 5px;
    font-size: .8em;

}
.list-header-div{
    display: flex;
    justify-content: space-between;
}

.list-title{
    color: rgb(56, 56, 56);
    padding: 1em 0;
    font-size: 1.2em;
    font-weight: 700;
}

.list-header{
    background: rgb(240, 239, 239);
    color: grey;
}

.list-table{
    border-collapse: collapse;
    width: 100%;
}
.list-table td{
    padding: 10px;
    font-size: 0.9em;
    /* min-width: 15em; */
}

.list-table tr{
    border-bottom: 1px solid rgb(236, 236, 236);
    /* background-color: pink; */
    transition: all 0.2s ease-in-out;
}

.list-table tr:hover{
    background: rgb(250, 250, 250);
}

.paggination-btn-div{
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    gap: 1em;
    align-items: center;
}

.paggination-btn-div>li>div{
    padding: 0.5em;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 0.2em;
    transition: all 0.3s ease-in-out; 
}

.paggination-btn-div>li>div:hover{
    background: rgba(211, 211, 211, 0.24);
}

.paggination-btn-div>li>a{
    padding: 0.5em;
    color: rgb(150, 150, 150);
    transition: all 0.3s ease-in-out;
}

.paggination-btn-div>li>a:hover
/* .paggination-btn-div>li>.active */
{
    background: var(--pri-color);
    color: white;
    border-radius: 0.2em;
}

.paggination-btn-div>li:hover{
    /* background: var(--pri-color); */
    /* cursor: pointer; */
}

.tax-file-status{
    background: #e6ffe6;
    width: fit-content;
    padding: 0.3em 0.5em;
    border-radius: 0.5em;
    font-weight: 700;
}

.list-footer{
    display: flex;
    width: 100%;
    padding: 1em;
}

.list-footer-inner-div{
    width: 100%;
    display: flex;
    gap: 1em;
    justify-content: space-between;
}

.pagination-view-div,
.pagination-goto-div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
}

.pagination-view-div select,
.pagination-goto-div input{
    outline: none;
    border: 1px solid rgb(199, 199, 199);
    border-radius: 0.2em;
    padding: 0.5em ;
}

.pagination-view-div p,
.pagination-goto-div p{
    font-size: 0.8em;
}

.pagination-goto-div input{
    min-width: 4em;
    width: 4em;
}

.pagination-goto-div button{
    padding: 0.5em;
    border: none;
    border-radius: 0.2em;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
}

.pagination-goto-div button:hover{
    background: rgb(224, 224, 224);
}

/* ============================================================================= */

.list-filters{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1em;
    padding: 1em 0;
}

.list-filters>div{
    flex-grow: 1;
}

.list-filter-input,
.list-filter-dropdown{
    display: flex;
    background: rgb(240, 240, 240);
    padding: 0.3em 0.5em; 
    align-items: center;
    border-radius: 0.5em;
    gap: 0.5em;
}

.list-filter-input>input,
.list-filter-dropdown>select{
    width: 100%;
    padding: 0.7em;
    border-radius: 0.5em;
    border: none;
    outline: none;
}

.list-filter-input>input::placeholder{
    color: rgb(211, 211, 211);
}

.list-filter-dropdown>select{
    color: rgb(139, 139, 139);
}

.list-filter-dropdown>select option{
    width: 100%;
    padding: 1em;
}

.list-filter-input>label,
.list-filter-dropdown>label{
    font-size: 0.9em;
    font-weight: 550;
    color: gray;
}


@media only screen and (min-width: 10px){
    .list-filter-input,
    .list-filter-dropdown{
        max-width: 100%;
        
    }
}

@media only screen and (min-width: 500px){
    .list-filter-input,
    .list-filter-dropdown{
        max-width: 48%;
        
    }
}

@media only screen and (min-width: 768px){
    .list-filter-input,
    .list-filter-dropdown{
        width: 30%;
    }
}

@media only screen and (min-width: 1200px){
    .list-filter-input,
    .list-filter-dropdown{
        width: 23%;
    }
}


/* ====================================================================================================================================================================
// ====================================================================================================================================================================
// ====================================================================================================================================================================
// ================2 List Wrapper====================================================================================================================================================
// ====================================================================================================================================================================
// ====================================================================================================================================================================
// ==================================================================================================================================================================== */


.advancelist-input{
    width: 100%;
    padding: 1em;
    border: 1px solid var(--border);
    outline: none;
    border-radius: 0.3em;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -ms-border-radius: 0.3em;
    -o-border-radius: 0.3em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.advancelist-input:hover,
.advancelist-input:focus{
    border: 1px solid rgba(163, 163, 163,1);
}

.advancelist-input::placeholder{
    color: lightgray;
}

.advanceListOverview{
    
    background: white;
    padding: 0em 1.5em;
    box-shadow: 0 0 2em 0.01em rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.advanceListOverviewHeader{
    padding: 1em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.advanceListOverviewContent{
    padding: 1em 0;
}

.advanceListOverviewContent>table{
    /* border: 1px solid rgba(0, 0, 0, 0.05); */
}

.advanceListOverviewContent>table>thead>tr>th, 
.advanceListOverviewContent>table>tbody>tr>td{
    padding: .8em;
    font-size: 0.8em;
    text-align: left;
    /* width: 15%; */
}

.advanceListOverviewContent>table>tbody>tr>td{
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.advanceListOverviewContent>table>thead>tr>th{
    font-weight: 600 !important;
    /* background: rgb(17, 28, 67, 0.2); */

}
.advanceListOverviewContent>table>thead>tr>th>p{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5em;
    
}

.advanceListOverviewContent>table>thead>tr>th>p>span{
    cursor: pointer;
}


.advanceListOverviewContent>table>tbody>tr:nth-child(odd){
    /* background: rgba(144, 106, 226, 0.05); */
}

.advanceListOverviewSearchBox>input{
    padding: 0.5em 1em;
    border: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.advanceListOverviewSearchBox>input:hover,
.advanceListOverviewSearchBox>input:focus{
    border: 1px solid rgba(144, 106, 226, 0.5);
    box-shadow: 0 0 0.5em 0.01em rgba(144, 106, 226, 0.1);
}

.advanceListOverviewBottom{
    padding: 1em 0;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

}

.advanceListOverviewBottom a{
    font-size: 0.8em;
    color: grey;
}

.advanceListOverviewBottom p{
    font-size: 0.8em;
}

.pagginationNumber{
    padding: 0.4em 0.9em;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.pagginationNumber:nth-child(1),
.pagginationNumber:hover{
    color: white;
    background: var(--dark-color);
}

.pagginationDiv{
    gap: 0.2em;
}

/* ==================================advanceList Overview Css=============================================================== */
