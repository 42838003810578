/* =================================Small Cards Css=========================================== */

.dashSmallCardSection{
    /* padding: 2em ; */
    background: white;
    box-shadow: 0 0 2em 0.01em rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.dashSmallCardIconDiv>.icon{
    padding: 1em;
}

.dashSmallCardIconDiv>.icon>svg{
    font-size: 2em;
}

/* =================================Small Cards Css=========================================== */


.panelDashboardContent{
    padding: 2em 0em ;
}

/* ================================Dashboard Css===================================================== */

input:disabled,
textarea:disabled{
    background: rgb(241, 241, 241);
}


/* =============================================================================================== */

.dash-card-wrapper-section{
    display: flex;
    width: 100%;
    gap: 1em;
    padding-bottom: 1em;
}

.dash-card-wrapper-section>div{
    flex-grow: 1;
}

.dash-card-section{
    display: flex;
    position: relative;
    gap: 0.2em;
    flex-direction: column;
    max-width: 25%;
    padding: 1em 2em;
    border-radius: 1em;
    box-shadow: 0 0 1em 0.1em rgba(0, 0, 0, 0.05);
}

.dash-card-icon-abs{
    position: absolute;
    right: 0.2em;
    font-size: 5em;
}

.dash-card-icon-abs svg{
    color: white !important;
    opacity: 0.3;
}

.dash-card-section>p{
    font-size: 0.9em;
    color: white;
}

.dash-card-section>h2{
    font-size: 3em;
    color: white;
}

