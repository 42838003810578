.inputError{
    color:red;
    font-size: 0.6em;
}

.dropdownContainer{
    margin-top: -0.2em;
}

.dropdownContainer label {
    font-size: 0.8em;
    width: 100%;

}

.dropdown{
    width: 100%;
    padding: 0.9em 1em;
    border: 1px solid var(--border);
    outline: none;
    background: white;
    cursor: pointer;
    border-radius: 0.3em;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -ms-border-radius: 0.3em;
    -o-border-radius: 0.3em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.dropdown:focus,
.dropdown:hover{
    border: 1px solid rgba(163, 163, 163,1);
}

/* ========================================Dropdown============================================================= */

.dropdown-section{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
}

.dropdown-inner-div{
    width: 100%;
    /* border: 1px solid var(--border); */
    border: 1px solid rgba(230,230,230);
    display: flex;
    height: 35px;
    align-items: center;
    justify-content: center;
    /* overflow: hidden; */
    border-radius: 0.3em;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -ms-border-radius: 0.3em;
    -o-border-radius: 0.3em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;

}

.dropdown{
    width: 100%;
    padding: 1em;
    outline: none;
}

.dropdown-inner-div:focus-within {
    border: 1px solid var(--border);
}

.dropdown-error-text{
    color: red;
    font-size: 0.6em;
    margin-top: 0.5em;
}
.dropdown-label{
    font-size: 0.85em;
}

/* ========================================Dropdown============================================================= */
