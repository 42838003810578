.panelSlideMenuSection{
    /* background: var(--dark-color); */
    height: 100vh;
    width: 100%;
    padding: 0.1em;
}

.panelSlideMenuLogo{
    width: 100%;
    /* height: 4em; */
    border-bottom: 1px solid var(--light-color);
}

.panelSlideMenuLogo>a{
    font-size: 2.25em;
    text-align: center;
    color: var(--dark-color);
}

.panelSlideMenu{
    width: 100%;
    height: fit-content;
}

.panelSlideMenu>ul{
    /* width: 100%; */
    height: 100%;
    padding: 1em 1em;
}

.panelSlideMenu>ul>li{
    padding: 0.1em 0em;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
}

.panelSlideMenu>ul>li>a{
    max-width: 100%;
    max-height: 100%;
    padding: 0.5em 1.5em; 
    opacity: 0.5;
    font-weight: 400;
    text-align: left;
    color: var(--dark-color);
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -ms-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.panelSlideMenu>ul>li .active, .panelSlideMenu ul li a:hover{
    background: var(--light-color);
    color: var(--dark-color);
    opacity: 1;

}

/* ==============================Panel Close Css====================================== */

.panelClosehoverDiv{
    position: absolute;
    padding: 0.5em 1em;
    background: var(--dark-color);
    color: white;
    left: 150%;
    /* display: none; */
    opacity: 1;
    min-width: 8em;
    z-index: 50;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
}

.panelCloseIcons{
    font-size: 2em;
    padding: 0.3em 0.3em !important; 
}

.panelSlideMenu ul li a:hover .panelClosehoverDiv{
    opacity: 0 !important;
    background: red;
}