.panelProductUpdateSection{
    /* padding: 2em; */
}

.panelProductUpdateSection>.panelProductUpdateDiv{
    background: white;
    padding: 2em;
    box-shadow: 0 0 2em 0.01em rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.panelProductUpdateDiv{
    width: 80%;
}

.panelProductUpdateImgContainer{
    width: 65%;
    
}

.panelProductUpdateImgContainer>div{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.panelProductUpdateImgDiv{
    
    padding-top: 125%;
    position: relative;
    width: 100%;
}

.panelProductUpdateImgAbsDiv{
    position: absolute;
    /* gap: 2.5em; */
    inset: 0;
    background: rgba(240,240,240,1);

}

.panelProductUpdateImgAbsDiv img{
    max-width: 100%;
    max-height: 100%;
}


.panelProductUpdateText{
    /* padding: 1em 2em; */
    background: white;
    width: 40%;
    /* box-shadow: 0 0 2em 0.01em rgba(0, 0, 0, 0.1); */
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}


.img-selector .img-preview{
    background: #EEEEEE;
    width: 200px;
    height: 250px;
    position: relative;
    border-radius:5px;
    border:2px solid purple
}
.img-preview img{
    max-width: 100%;
    max-height: 100%;
}

@media only screen and (min-width: 100px){
    .panelProductUpdateSection>div{
        width: 100%;
    }
    .panelProductUpdateDiv{
        width: 100%;
    }

    .panelProductUpdateSection{
        /* padding: 2em; */
    }

    .panelProductUpdateDiv>div{
        flex-direction: column;
    }

    .panelProductUpdateImgContainer{
        width: 100%;
    }

    .panelProductUpdateImgContainer>div{
        flex-direction: column;
    }

    .panelProductUpdateImgDiv{
        padding-top: 125%;
    }

    .panelProductUpdateText>div{
        flex-direction: column;
    }

    .panelProductUpdateText{
        width: 100%;
    }
}

@media only screen and (min-width: 768px){
    
    .panelProductUpdateDiv{
        width: 90%;
    }

    .panelProductUpdateImgContainer>div{
        flex-direction: row;
    }

    .panelProductUpdateImgDiv{
        padding-top: 125%;
    }

    .productTextContentSection>.buttonSection button{
        padding: 0.5em 1.5em;
        font-size: 1em;
    }

    .productTextContentSection>.buttonSection{
        width: 60%;
    }

    .panelProductUpdateSection>div{
        /* width: 90%; */
    }

    .panelProductUpdateText>div{
        flex-direction: row;
    }
}

@media only screen and (min-width: 1200px){
    

    .panelProductUpdateSection{
        /* padding: 2em; */
    }

    .panelProductUpdateDiv>div{
        flex-direction: row;
    }

    .panelProductUpdateImgContainer{
        width: 50%;
    }

    .panelProductUpdateText{
        width: 50%;
    }
}


@media only screen and (min-width: 1500px){

    .panelProductUpdateSection{
        /* padding: 2em; */
    }

}


/* ========================================================================================================== */


.imageFileSection{
    width: 100%;
    padding-top: 122%;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
}

.imageContainerAbs{
    position: absolute;
    inset: 0;
}

.imageContainerAbs img{
    max-width: 100%;
    max-height: 100%;
}

.imageFileSection>input[type='file']{
    width: 100%;
    z-index: 0;
    opacity: 0;
    cursor: pointer;
}

.imageFileOverDiv{
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.1) ;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(2px);
}

.imageFileAbsDiv{
    position: absolute;
    top: 1em;
    right: 1em;
}