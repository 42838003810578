.image-icon-section{
    width: 100%;
    padding-top: 66.66%;
    position: relative;
}

.image-icon-abs-div{
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-icon-abs-div>img{
    max-width: 100%;
    max-height: 100%;
}