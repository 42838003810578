.textAreaSection{
    width: 100%;
    gap: 0.5em
}

.textArea{
    width: 100%;
    
    padding: 1em;
    border: 1px solid var(--border);
    outline: none;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    resize: none;
}

.textArea:hover,
.textArea:focus{
    border: 1px solid rgba(163, 163, 163,1);
}

.textAreaLabel{
    font-size: 0.9em;
    font-weight: 400;
}

.textAreaWarning{
    font-size: 0.8em;
    color: rgb(163, 163, 163);
}



.inputError{
    font-size: 0.6em;
    /* color: rgb(163, 163, 163); */
    color:red
}