/* ==================================Product Overview Css=============================================================== */

.orderOverview{
    background: white;
    padding: 0em 1.5em;
    box-shadow: 0 0 2em 0.01em rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
}

.orderOverviewHeader{
    padding: 1em 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.orderOverviewContent{
    padding: 1em 0;
}

.orderOverviewContent>table{
    /* border: 1px solid rgba(0, 0, 0, 0.05); */
}

.orderOverviewContent>table>thead>tr>td, 
.orderOverviewContent>table>tbody>tr>td{
    padding: 1em;
    font-size: 0.8em;
    width: 12%;
}

.orderOverviewContent>table>tbody>tr>td{
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.orderOverviewContent>table>thead>tr>td{
    font-weight: 600;
    
    /* background: rgb(17, 28, 67, 0.2); */

}

.orderOverviewContent>table>tbody>tr:nth-child(odd){
    /* background: rgba(144, 106, 226, 0.05); */
}

.orderOverviewSearchBox>input{
    padding: 0.5em 1em;
    border: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.orderOverviewSearchBox>input:hover,
.orderOverviewSearchBox>input:focus{
    border: 1px solid rgba(144, 106, 226, 0.5);
    box-shadow: 0 0 0.5em 0.01em rgba(144, 106, 226, 0.1);
}

.orderOverviewBottom{
    padding: 1em 0;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);

}

.orderOverviewBottom a{
    font-size: 0.8em;
    color: grey;
}

.orderOverviewBottom p{
    font-size: 0.8em;
}

.pagginationNumber{
    padding: 0.4em 0.9em;
    border-radius: 0.5em;
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    -ms-border-radius: 0.5em;
    -o-border-radius: 0.5em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.pagginationNumber:nth-child(1),
.pagginationNumber:hover{
    color: white;
    background: var(--dark-color);
}

.pagginationDiv{
    gap: 0.2em;
}

/* ==================================Product Overview Css=============================================================== */
