
.croper-image-wrapper{
    width: 100%;
    padding-top: 122%;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: rgb(243, 243, 243);
}

.cropper-image-preview{
    position: absolute;
    inset: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cropper-image-preview img{
    width: 50%;
    max-width: 100%;
    max-height: 100%;
}

.croper-image-wrapper>input[type='file']{
    width: 100%;
    z-index: 0;
    opacity: 0;
    cursor: pointer;
}

.cropper-image-over-div{
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.1) ;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(2px);
}

.cropper-image-abs-div{
    position: absolute;
    top: 1em;
    right: 1em;
}
