.button{
    padding: 1em  2em;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 0.2em;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    -ms-border-radius: 0.2em;
    -o-border-radius: 0.2em;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
}

.transparent-btn{
    background: transparent;
    padding: 0;
}

.outlined-btn{
    border: 1px solid var(--btn-outline);
    background: transparent;
    color: var(--btn-outline);
}

.outlined-btn:hover{
    border: 1px solid var(--btn-outline);
    color: var(--sec-color);
    background: var(--btn-outline);
}

.filled-btn{
    border: 1px solid var(--dark-color);
    color: var(--sec-color);
    background: var(--dark-color);
    opacity: 0.9;
}

.filled-btn:hover{
    opacity: 1;
}

.filled-btn-red{
    border: 1px solid #ef3f49;
    color: var(--sec-color);
    background: #ef3f49;
    opacity: 0.9;
}

.filled-btn-red:hover{
    opacity: 1;
}